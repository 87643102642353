import React from 'react'
import PhotoCrousel from './PhotoCarousel'
import Badge from 'react-bootstrap/Badge'
class Home extends React.Component
{
  componentDidMount()
  {
    localStorage.clear()
  }
	render()
	{
		return (
      <React.Fragment>
        <PhotoCrousel/>
{/*        <div className="text-center mx-3">
            <h2><Badge variant="success">Featured</Badge></h2>
            <div className="row">
              <div class="card" style={{width: "16rem"}}>
  <img class="card-img-top" src="movieslogo.jpg" alt="Card image cap"/>
  <div class="card-body">
    <h5 class="card-title">Movies</h5>
  </div>
</div>
            </div>
        </div>*/}
      </React.Fragment>
      )
	}
}

export default Home