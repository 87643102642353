import React from 'react';
import Header from './Components/Header'
import Livetv from './Components/Livetv'
import Home from './Components/Home'
import WatchTV from './Components/WatchTV'
import WatchMovie from './Components/WatchMovie'

import 'video-react/dist/video-react.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

import './App.css';
import 'bootstrap/dist/css/bootstrap.css'
function App() {
  return (
    <React.Fragment>
      
      <Router>
      <Header/>
      <div className="container mt-3">
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/livetv" component={Livetv}/>
        <Route exact path="/watchtv" component={WatchTV}/>
       

        <Route exact path="/watchmovie" component={WatchMovie}/>
      </Switch>
      </div>
        </Router>
    </React.Fragment>
  );
}

export default App;
